<template>
  <div class="ap">
    <h3>视光诊断</h3>
    <ul class="title">
      <li class="dy"><h4>检查结果</h4></li>
      <li>球镜</li>
      <li>柱镜</li>
      <li>轴向</li>
      <li>ADD</li>
      <li style="width: 6em;">远用矫正视力</li>
      <li style="width: 6em;">近用矫正视力</li>
      <li></li>
    </ul>
    <div v-if="tebst===1">
      <ul class="title content">
        <li class="dy">右眼</li>
        <li>{{postData.S_OP_Json.Refractive_DSOD}}</li>
        <li>{{postData.S_OP_Json.Refractive_DCOD}}</li>
        <li>{{postData.S_OP_Json.Refractive_XOD}}</li>
        <li>{{postData.S_OP_Json.Refractive_ADDOD}}</li>
        <li style="width: 6em;">{{postData.S_OP_Json.Refractive_DVAOD}}</li>
        <li style="width: 6em;">{{postData.S_OP_Json.Refractive_NVAOD}}</li>
        <li class="nb">
          <el-select class="xtxz" v-model="tebst" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select></li>
      </ul>
      <ul class="title content">
        <li class="dy">左眼</li>
        <li>{{postData.S_OP_Json.Refractive_DSOS}}</li>
        <li>{{postData.S_OP_Json.Refractive_DCOS}}</li>
        <li>{{postData.S_OP_Json.Refractive_XOS}}</li>
        <li>{{postData.S_OP_Json.Refractive_ADDOS}}</li>
        <li style="width: 6em;">{{postData.S_OP_Json.Refractive_DVAOS}}</li>
        <li style="width: 6em;">{{postData.S_OP_Json.Refractive_NVAOD}}</li>
        <li class="nb"><el-button class="sgn" size="small" type="primary">视功能详情</el-button></li>
      </ul>
    </div>
    <div  v-if="tebst===2">
      <ul class="title content">
        <li class="dy">右眼</li>
        <li>{{postData.S_OP_Json.Refractive_DSOD_S}}</li>
        <li>{{postData.S_OP_Json.Refractive_DCOD_S}}</li>
        <li>{{postData.S_OP_Json.Refractive_XOD_S}}</li>
        <li>{{postData.S_OP_Json.Refractive_ADDOD_S}}</li>
        <li>{{postData.S_OP_Json.Refractive_DVAOD_S}}</li>
        <li>{{postData.S_OP_Json.Refractive_NVAOD_S}}</li>
        <li class="nb">
          <el-select class="xtxz" v-model="tebst" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select></li>
      </ul>
      <ul class="title content">
        <li class="dy">左眼</li>
        <li>{{postData.S_OP_Json.Refractive_DSOS_S}}</li>
        <li>{{postData.S_OP_Json.Refractive_DCOS_S}}</li>
        <li>{{postData.S_OP_Json.Refractive_XOS_S}}</li>
        <li>{{postData.S_OP_Json.Refractive_ADDOS_S}}</li>
        <li>{{postData.S_OP_Json.Refractive_DVAOS_S}}</li>
        <li>{{postData.S_OP_Json.Refractive_NVAOD_S}}</li>
        <li class="nb"><el-button class="sgn" size="small" type="primary">视功能详情</el-button></li>
      </ul>
    </div>

    <div class="icdBox">
      <div class="zdjl">
        <ul class="title">
          <li class="dy" style="padding-right: .45rem;width: 1.1rem;"><h4>诊断结果</h4></li>
          <li class="oth">远视</li>
          <li class="oth">近视</li>
          <li class="oth">散光</li>
          <li class="oth">弱视</li>
          <li class="oth">老视</li>
          <li class="oth">正视</li>
        </ul>
        <div class="xz">
          <div class="yj">右眼</div>
          <el-checkbox-group v-model="checkList" class="dxs">
            <div class="dxxz"><el-checkbox :label="0"></el-checkbox></div>
            <div class="dxxz"><el-checkbox :label="2"></el-checkbox></div>
            <div class="dxxz"><el-checkbox :label="4"></el-checkbox></div>
            <div class="dxxz"><el-checkbox :label="6"></el-checkbox></div>
            <div class="dxxz"><el-checkbox :label="8"></el-checkbox></div>
            <div class="dxxz"><el-checkbox :label="10"></el-checkbox></div>
          </el-checkbox-group>
        </div>
        <div class="xz" style="position: relative;top: -2px;">
          <div class="yj">左眼</div>
          <el-checkbox-group v-model="checkList" class="dxs">
            <div class="dxxz"><el-checkbox :label="1"></el-checkbox></div>
            <div class="dxxz"><el-checkbox :label="3"></el-checkbox></div>
            <div class="dxxz"><el-checkbox :label="5"></el-checkbox></div>
            <div class="dxxz"><el-checkbox :label="7"></el-checkbox></div>
            <div class="dxxz"><el-checkbox :label="9"></el-checkbox></div>
            <div class="dxxz"><el-checkbox :label="11"></el-checkbox></div>
          </el-checkbox-group>
        </div>

        <div class="box">
          <h4>双眼视功能</h4>
          <div class="xz yz" style="width: 8.1rem !important;">
            <el-input
                disabled
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入内容"
                v-model="sysgn">
            </el-input>
          </div>
        </div>
      </div>
      <div class="icd">
        <div class="sou">
          <p>ICD编码:</p>
          <el-input class="srk" v-model="ICDCODE" placeholder="请输入内容"></el-input>
          <el-popover
              placement="bottom"
              width="300"
              trigger="manual"
              v-model="icdShow">
            <ul class="icdlistss">
              <li v-for="item in icdgetList" @click="addicd(item)">{{item.ICDCode}}:{{item.ICDName}}</li>
            </ul>
            <i slot="reference" @click="geticd" class="el-icon-search"></i>
          </el-popover>
        </div>
        <ul class="icd-nr">
          <li v-for="(item, idx) in icdlist">
            <el-tooltip placement="top" effect="light">
              <div slot="content"><el-button size="small" @click="deleicd(idx)" type="danger">删除</el-button></div>
              <p class="icdnrs">{{idx+1}}. {{item}}</p>
            </el-tooltip>
          </li>
        </ul>
      </div>
    </div>

    <div class="box">
      <h4>备注</h4>
      <div class="xz yz">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="postData.S_OP_Json.Refractive_Comment">
        </el-input>
      </div>
    </div>

    <div class="hx"></div>

    <div class="box">
      <h4>AI处方建议</h4>
      <div class="xz yz">
        <el-input
            disabled
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6}"
            placeholder="请输入内容"
            :value="postData.S_OP_Json.StringRefractive_Suggestion">
        </el-input>
      </div>
    </div>

    <div class="box">
      <div>
        <h4>诊断方案</h4>
        <i @click="tb" style="font-size: 22px;" class="el-icon-sort"></i>
      </div>
      <div class="xz yz">
        <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6}"
            placeholder="请输入内容"
            v-model="postData.S_OP_Json.StringRefractive_SuggestionFromAPP">
        </el-input>
      </div>
    </div>
    <div class="box">
      <h4>处方方案</h4>
      <div class="xz">
        <el-checkbox-group v-model="Solution" class="dx">
          <el-checkbox class="xxbos" :label="0">框架配镜</el-checkbox>
          <el-checkbox class="xxbos" :label="1">预防管理</el-checkbox>
          <el-checkbox class="xxbos" :label="2">视觉训练</el-checkbox>
          <el-checkbox class="xxbos" :label="3">角膜接触镜</el-checkbox>
          <el-checkbox class="xxbos" :label="4">斜弱视治疗</el-checkbox>
          <!--                    <el-checkbox class="xxbos" :label="5">屈光手术</el-checkbox>-->
        </el-checkbox-group>
        <el-button type="primary" class="add" size="small" @click="showyyss=true">屈光手术</el-button>
<!--        <el-button type="primary" class="add" size="small" @click="showadd=true">添加</el-button>-->
      </div>
    </div>


    <el-dialog
        title="添加处方方案"
        :visible.sync="showadd"
        width="30%"
    >
      <p>名称</p>
      <el-input class="addnr" v-model="addtitle" placeholder="请输入内容"></el-input>
      <p>内容</p>
      <el-input
          class="addnr"
          type="textarea"
          resize="none"
          :rows="4"
          placeholder="请输入内容"
          v-model="addnr">
      </el-input>
      <el-button type="primary" @click="showadd = false">确 定</el-button>
    </el-dialog>

    <!--      <div class="mg" v-if="showyyss">-->
    <!--        <SSYY @close="closexh"></SSYY>-->
    <!--      </div>-->
    <el-dialog
        title="请选择手术"
        :visible.sync="showyyss"
        width="60%"
    >
      <div style="display: flex;justify-content: center;">
        <div class="yydc-xx" style="align-items: flex-start;">
          <span>*</span>
          <p class="bt">拟施手术：</p>
          <div style="padding-left: 10px;">
            <div class="yydc-yb">
              <p @click="teb('ssxzod')" class="dg"><i v-if="ssxzod" class="el-icon-check"></i></p>
              <div>OD</div>
              <el-cascader class="ybxz" :options="odsops" v-model="ssvalOD" :disabled="!ssxzod" laceholder="请选择" :show-all-levels="false"></el-cascader>
              <!--                <el-select class="ybxz" v-model="ssvalOD"  :disabled="!ssxzod" placeholder="请选择">-->
              <!--                  <el-option-->
              <!--                      v-for="item in odsops"-->
              <!--                      :key="item.value"-->
              <!--                      :label="item.label"-->
              <!--                      :value="item.value">-->
              <!--                  </el-option>-->
              <!--                </el-select>-->
            </div>
            <div class="yydc-yb">
              <p  @click="teb('ssxzos')" class="dg"><i v-if="ssxzos" class="el-icon-check"></i></p>
              <div>OS</div>
              <el-cascader class="ybxz" :options="odsops" v-model="ssvalOS" :disabled="!ssxzos" laceholder="请选择" :show-all-levels="false"></el-cascader>
              <!--                <el-select class="ybxz" v-model="ssvalOS" :disabled="!ssxzos"    placeholder="请选择">-->
              <!--                  <el-option-->
              <!--                      v-for="item in odsops"-->
              <!--                      :key="item.value"-->
              <!--                      :label="item.label"-->
              <!--                      :value="item.value">-->
              <!--                  </el-option>-->
              <!--                </el-select>-->
            </div>
            <div class="yydc-yb">
              <p  @click="teb('ssxzou')" class="dg"><i v-if="ssxzou" class="el-icon-check"></i></p>
              <div>OU</div>
              <el-cascader class="ybxz" :options="odsops" v-model="ssvalOU" :disabled="!ssxzou" laceholder="请选择" :show-all-levels="false"></el-cascader>
              <!--                <el-select class="ybxz" v-model="ssvalOU" :disabled="!ssxzou"   placeholder="请选择">-->
              <!--                  <el-option-->
              <!--                      v-for="item in odsops"-->
              <!--                      :key="item.value"-->
              <!--                      :label="item.label"-->
              <!--                      :value="item.value">-->
              <!--                  </el-option>-->
              <!--                </el-select>-->
            </div>
            <el-button type="primary" size="small" @click="saveyyss">提交</el-button>
          </div>
        </div>
      </div>

    </el-dialog>
    <el-button type="primary" style="margin-top: 30px;"  @click="vsave">保存</el-button>
    <el-button type="primary" style="margin-top: 30px;"  v-if="isbsd" @click="bsdtj">宝视达提交</el-button>
    <el-button type="primary" style="margin-top: 30px;"  v-if="showyzzyy" @click="yzzyy">发送到his/pos</el-button>
  </div>
</template>

<script>
export default {
  name: "viewDiagnosis",
  data() {
    return {
      isbsd: false,
      sysgn: '',
      ssvalOD: '',
      ssvalOS: '',
      ssvalOU: '',
      odsops: [
        {
          value: '晶体植入手术',
          label: '晶体植入手术',
          children: [
            {
              value: 'PRL',
              label: 'PRL'
            },
            {
              value: 'ICL',
              label: 'ICL'
            },
            {
              value: 'TICL',
              label: 'TICL'
            }
          ]
        },
        {
          value: '角膜激光手术',
          label: '角膜激光手术',
          children: [
            {
              value: '德国鹰视PTK',
              label: '德国鹰视PTK'
            },
            {
              value: '精雕飞秒+睛春clear+Q',
              label: '精雕飞秒+睛春clear+Q'
            },
            {
              value: '普通飞秒激光',
              label: '普通飞秒激光'
            },

            {
              value: '超薄flap+lasik+Q',
              label: '超薄flap+lasik+Q'
            },

            {
              value: '超薄普通飞秒',
              label: '超薄普通飞秒'
            },
            {
              value: '二次增效手术',
              label: '二次增效手术'
            },
            {
              value: '精雕飞秒+Q',
              label: '精雕飞秒+Q'
            },
            {
              value: '蔡司全飞秒SMILE（薄帽）',
              label: '蔡司全飞秒SMILE（薄帽）'
            },
            {
              value: '德国鹰视PTK+Q传统LASEK',
              label: '德国鹰视PTK+Q传统LASEK'
            },
            {
              value: '蔡司全飞秒SMILE',
              label: '蔡司全飞秒SMILE'
            },
            {
              value: '精雕飞秒+睛春clear',
              label: '精雕飞秒+睛春clear'
            },
            {
              value: '全飞秒+散光自旋补偿',
              label: '全飞秒+散光自旋补偿'
            }
          ]
        },
        {
          value: '白内障手术',
          label: '白内障手术',
          children: [
            {
              value: 'PHACO+IOL植入术',
              label: 'PHACO+IOL植入术'
            }
          ]
        },
      ],
      itemodsarr: [],
      ssxzos: false,
      ssxzod: false,
      ssxzou: false,


      showyyss: false,
      showadd: false,
      addtitle: '',
      addnr: '',
      checkList: [],
      textarea2: '',
      postData: {S_OP_Json: {}},
      Solution: [],
      value: '0',
      options: [
        {
          value: 1,
          label: '小瞳验光'
        },
        {
          value: 2,
          label: '散瞳验光'
        },
      ],
      ICDCODE: '',
      icdShow: false,
      icdgetList: [],
      icdlist: [],
      tebst: 1, //1是小瞳，2散瞳

      ysdata: '',
      showyzzyy: false
    }
  },
  computed: {
    valAI() {
      let str = ''
      let arr = this.postData.S_OP_Json.ListVisualFunction_Suggestion
      if (arr && arr.length > 0) {
        str = 0
        for (let i = 0; i < arr.length; i++) {
          str = arr[i].EyeCheckCondition + ':' + '\n'
          for (let k = 0; k < arr[i].ListRecomm.length; k++) {
            str += arr[i].ListRecomm[k] + '\n'
          }
        }
      }
      return str
    }
  },
  watch: {
    'postData': {
      handler(n, o) {
        this.$store.commit('expertClinic/upExpertClinicData', n)
      },
      deep: true,
      immediate: true
    },
    checkList: {
      handler(n) {
        let arr = Array(12).fill(0)
        for (let i = 0; i < n.length; i++) {
          arr[Number(n[i])] = 1
        }
        this.postData.S_OP_Json.Refractive_Diagnosis = arr.join(',')
      },
      deep: true
    },
    Solution: {
      handler(n) {
        let arr = Array(6).fill(0)
        for (let i = 0; i < n.length; i++) {
          arr[n[i]] = 1
        }
        this.postData.S_OP_Json.Refractive_Solution = arr.join(',')
      },
      deep: true
    },
    icdlist: {
      handler(n) {
        if (n) {
          this.postData.S_OP_Json.ICD_Diagnosis = this.icdlist.join(',')
        }
      },
      deep: true
    },
    "$store.state.expertClinic.isupData": function (n, o) {
      this.postData = JSON.parse(JSON.stringify(this.$store.state.expertClinic.expertClinicData))
      this.getChooseList(this.postData.S_OP_ID)
      // console.log(this.postData)
      let str = this.postData.S_OP_Json.ICD_Diagnosis
      this.icdlist = str?str.split(','):[]
      this.checkList = this.clStr(this.postData.S_OP_Json.Refractive_Diagnosis)
      this.Solution = this.clStr(this.postData.S_OP_Json.Refractive_Solution)
      if (this.postData.S_OP_Json.ListVisualFunction_Suggestion.length) {
        let str = ''
        for (let i = 0; i < this.postData.S_OP_Json.ListVisualFunction_Suggestion.length; i++) {
          str = str + this.postData.S_OP_Json.ListVisualFunction_Suggestion[i].EyeCheckCondition + '，'
        }
        this.sysgn = str
      }
    }
  },
  created() {
    let arrConfig = this.$store.state.users.user.Company.ListCompanyConfig
    if (arrConfig && arrConfig.length > 0) {
      for (let i = 0; i < arrConfig.length; i++) {
        if (arrConfig[i].BCKey == "BSD_Sync" && arrConfig[i].BCValue == 1) this.isbsd = true
      }
    }


    this.showyzzyy = this.$store.state.users.user.Company.CompanyId == 'e2d78ed9-980c-49b4-9aab-f38acb9e8d9e'?true:false


    this._api.expertClinic.getODOS()
        .then(res => {
          // console.log(res)
          let arr = []
          if (res.GetSimpleListResult && res.GetSimpleListResult.length) {
            this.itemodsarr = [...res.GetSimpleListResult]
            for (let i = 0; i < res.GetSimpleListResult.length; i++) {
              arr.push({
                value: res.GetSimpleListResult[i].ProductId,
                label: res.GetSimpleListResult[i].ProductName
              })
            }
          }
          //this.odsops = [...arr]
          arr = null
        })


    this._api.expertClinic.OA_Get()
        .then(res => {
          if (res.Result) {
            this.ysdata = JSON.parse(JSON.stringify(res.Result))
            if (this.ysdata.OAODOperationName) {
              this.ssxzod = true
              this.ssvalOD = this.ysdata.OAODOperationName
            }
            if (this.ysdata.OAOSOperationName) {
              this.ssxzos = true
              this.ssvalOS = this.ysdata.OAOSOperationName
            }
            if (this.ysdata.OAOUOperationName) {
              this.ssxzou = true
              this.ssvalOU = this.ysdata.OAOUOperationName
            }
          }
        })

    if (!this.$store.state.users.employees) {
      return false
    }
  },
  methods: {
    closexh() {
      this.showyyss = false
    },
    clStr(str, num=10) {
      if (!str) return []
      let arr = [], item = str.split(',')
      for (let i = 0; i < item.length; i++) {
        if (Number(item[i])) {
          arr.push(i)
        }
      }
      return arr
    },
    tb() {
      this.$set(this.postData.S_OP_Json, 'StringRefractive_SuggestionFromAPP', this.postData.S_OP_Json.StringRefractive_Suggestion)
    },
    geticd() {
      if (!this.ICDCODE) {
        return this.$alert('请输入内容', '提示')
      }
      let lig = this.$loading({
        text: '加载中....'
      })
      this._api.expertClinic.getICDCODE(this.ICDCODE)
          .then(res => {
            lig.close()
            this.icdShow = true
            if (res.GetListResult && res.GetListResult.length) {
              this.icdgetList = JSON.parse(JSON.stringify(res.GetListResult))
            }else {
              this.icdgetList = []
            }
          })
    },
    deleicd(idx) {
      this.icdlist.splice(idx, 1)
    },
    addicd(item) {
      let str = item.ICDCode +":"+item.ICDName
      if (!this.icdlist.some(item => item==str)) {
        this.icdlist.push(str)
      }
      this.icdShow = false
    },

    teb(key) {
      if (key == 'ssxzou' && this.ssxzou == false) {
        this.ssxzou = true
        this.ssxzod = false
        this.ssxzos = false
        this.ssvalOD = ''
        this.ssvalOS = ''

      }else {
        this[key] = !this[key]
        this.ssxzou = false
        this.ssvalOU = ''

        if (key == 'ssxzou' && !this.ssxzou) {
          this.ssvalOU = ''
        }
        if (key == 'ssxzod' && !this.ssxzod) {
          this.ssvalOD = ''
        }
        if (key == 'ssxzos' && !this.ssxzos) {
          this.ssvalOS = ''
        }
      }
    },

    saveyyss() {
      // console.log(this.postData)
      // return

      if (this.ysdata.OAChooseId) {

        if (this.ysdata.OAState == 'pre') {
          this.ysdata.OAODOperationName = this.ssvalOD
          this.ysdata.OAOSOperationName = this.ssvalOS
          this.ysdata.OAOUOperationName = this.ssvalOU

          let log = this.$loading({
            background: 'rgba(0, 0, 0, 0.7)',
            text: '保存中...'
          })

          this._http.post(this._getUrl('OA_Update'), {
            "token": {
              "TimeSpan": 1616479500,
              "sToken": "4D6A372AC044AC7A41331B9141F46536"
            },
            o: this.ysdata
          })
              .then(res => {
                log.close()
                if (res.ReturnCode == 5000) {
                  this.$alert('提交成功！', '提示')
                }else {
                  this.$alert('提交失败！请稍后再试', '提示')
                }
              })
        }else {
          return this.$alert('已预约或手术中无法修改', '提示')
        }


        return;
      }


      let postdata = {
        "OACompanyId": this.$store.state.users.user.Company.CompanyId,
        "OAShopId": this.$store.state.bases.shop.ShopId,
        "OAChooseId": this.$store.state.physicianVisits.xzConsulting,
        "OACustomerId": this.$store.state.users.CsUser.CustomerId,
        "OAAddExpertId": this.$store.state.users.employees.ExpertId,
        "OAEyes": "uo",
        "OAODOperationName": "",
        "OAOSOperationName": '',
        "OAOUOperationName": '',
        "OAState":"pre"
      }, item = ''
      if (this.ssxzod) {
        if (!this.ssvalOD) return this.$alert('请选择OD手术!', '提示')
      }

      if (this.ssxzos) {
        if (!this.ssvalOS) return this.$alert('请选择OS手术!', '提示')
      }

      if (this.ssxzou) {
        if (!this.ssvalOU) return this.$alert('请选择OU手术!', '提示')
      }


      if (typeof this.ssvalOD == 'object') {
        postdata.OAODOperationName = this.ssvalOD&&this.ssvalOD.length?this.ssvalOD[1]:''
      }else {
        postdata.OAODOperationName = this.ssvalOD
      }

      if (typeof this.ssvalOS == 'object') {
        postdata.OAOSOperationName = this.ssvalOS&&this.ssvalOS.length?this.ssvalOS[1]:''
      }else {
        postdata.OAOSOperationName = this.ssvalOS
      }

      if (typeof this.ssvalOU == 'object') {
        postdata.OAOUOperationName = this.ssvalOU&&this.ssvalOU.length?this.ssvalOU[1]:''
      }else {
        postdata.OAOUOperationName = this.ssvalOU
      }

      let log = this.$loading({
        text: '保存中...'
      })


      this._api.expertClinic.frameDetailAddToCart(postdata)
          .then(res => {
            log.close()
            if (res.ReturnCode == 5000) {
              this.$alert('保存成功！', '提示')
            }else {
              this.$alert('保存失败！', '提示')
            }
          })
    },

    getcp(id) {
      for (let i = 0; i < this.itemodsarr.length; i++) {
        if (this.itemodsarr[i].ProductId === id) {
          return this.itemodsarr[i]
        }
      }
    },

    getChooseList(id) {
      this._api.expertClinic.getChooseList(id)
          .then(res => {
            // console.log(res)
            if (res.GetListResult && res.GetListResult.length) {
              for (let i = 0; i < res.GetListResult.length; i++) {
                if (res.GetListResult[i].CHPLeftRightEyes === 'OD') {
                  this.ssxzod = true
                  this.ssvalOD= res.GetListResult[i].CHPProductId
                }
                if (res.GetListResult[i].CHPLeftRightEyes === 'OS') {
                  this.ssxzos= true
                  this.ssvalOS = res.GetListResult[i].CHPProductId
                }
                if (res.GetListResult[i].CHPLeftRightEyes === 'OU') {
                  this.ssxzou = true
                  this.ssvalOU = res.GetListResult[i].CHPProductId
                }
              }
            }
          })
    },

    vsave() {
      if (this.$store.state.expertClinic.isSave !== 'Success') {
        return this.$alert('去充值', '提示')
            .then(res => {
              this.$router.push({path: '/ifame2', query: {type: 0}})
            })
      }
      let arr = []
      let datas = this.$store.state.expertClinic.expertClinicData.S_OP_Json
      arr[0] = {
        key: 'Refractive_Diagnosis',
        value: datas.Refractive_Diagnosis
      }
      arr[1] = {
        key: 'Refractive_Solution',
        value: datas.Refractive_Solution
      }
      arr[2] = {
        key: 'Refractive_Comment',
        value: datas.Refractive_Comment
      }
      arr[3] = {
        key: 'StringRefractive_SuggestionFromAPP',
        value: datas.StringRefractive_SuggestionFromAPP
      }
      arr[4] = {
        key: 'CheckResult_DealSuggestion_Des',
        value: datas.CheckResult_DealSuggestion_Des
      }
      arr[5] = {
        key: 'ICD_Diagnosis',
        value: datas.ICD_Diagnosis
      }
      this.$store.commit('isbc/upZjzs', {key: 'Recomm2', val: true})
      this._api.expertClinic.saveExpertClinic(arr, '视光诊断')
    },

    bsdtj() {
      let ling = this.$loading({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
      })
      this._api.expertClinic.BSDtj()
          .then(res => {
            ling.close()
            if (res) {
              this.$alert(res.displaymsg, '提示')
            }
          })
    },

    yzzyy() {
      let _this = this
      window.localRequestResult = function (str) {
        console.log(str)
        try {
          let obj = JSON.parse(str)
          _this.$alert(obj.message, '提示')
        }catch (e) {
          _this.$alert(str, '提示')
          console.log(e)
        }
      }

      let obj = {
        "DevCode": "sdzy",//固定字符串
        "CompanyCode": "yzzyy",//固定字符串
        "CompanyId": "e2d78ed9-980c-49b4-9aab-f38acb9e8d9e",//固定字符串
        "ChooseId": this.$store.state.physicianVisits.xzConsulting,//中视觉的接诊id
        "COShopId": this.$store.state.bases.shop.ShopId,//固定空字符串
        "CustomerId": this.$store.state.users.CsUser.CustomerId,//中视觉的顾客id
        "S_OP_Id": "",//固定空字符串
        "COExpertId": this.$store.state.users.employees.ExpertId,//中视觉的expertid
        "COAddUserId": "2584bd90-3722-4161-a35b-7f214403475a"//固定字符串
      }
      console.log(JSON.stringify(obj))
      // console.log('http://192.168.1.36:55871/api/Visumall/AcceptPresBasicInfo')
      console.log('http://218.65.220.230:8081/api/Visumall/AcceptPresBasicInfo')
      if (window.bound) {
        // window.bound.localRequest('http://192.168.1.36:55871/api/Visumall/AcceptPresBasicInfo', JSON.stringify(obj))
        window.bound.localRequest('http://218.65.220.230:8081/api/Visumall/AcceptPresBasicInfo', JSON.stringify(obj))
      }
    }
  },
  components: {
    SSYY: () => import('../reserveList/surgeryAppointment')
  }
}
</script>

<style scoped lang="scss">
.ap {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
}
h3 {padding-top: .4rem}
.title {
  display: flex;
  align-items: center;
  justify-content: start;
  li {
    width: 1.1rem;
    padding: .1rem .2rem;
    margin: .05rem .1rem;
  }
  .oth{width: .75rem;}
  .dy {
    padding-right: .3rem;
    margin: .05rem 0;
    background: #ffffff;
  }
  .nb {
    background: #ffffff; text-align: left; width: 1.6rem;
    padding: 0;
    position: relative;
    .xtxz {
      position: absolute;
      top: -10px;
    }
    .sgn{
      position: absolute;
      top: -5px;
    }
  }
}
.content {
  li {
    background: #f6f6f6;
    border-radius: 5px;
    height: 20px;
  }
}

.xz {
  display: flex;
  justify-content: start;
  align-items: center;
  .dxs {
    display: flex;
    justify-content: start;
    align-items: center;
    .dxxz {
      width: .65rem;
      padding: .15rem .35rem;
      background: #f6f6f6;
    }
  }
  .yj {
    width: 1.2rem;
    padding: .1rem .2rem;
    padding-right: .35rem;
    margin: 0;
    background: #ffffff;
  }
}

.box {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  padding:.2rem 0;
  h4 {
    width: 1.2rem;
    padding: .1rem .2rem;
    /*margin: .05rem .15rem;*/
  }
  .xz {
    display: flex;
    flex-wrap: wrap;
    width: 11.4rem;
    background: #f6f6f6;
    padding: 0.2rem 0;
    padding-left: .2rem;
    margin-left: .15rem;
    border-radius: 5px;
  }
  .add {
    margin-left: .2rem;
  }
  .dx {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: .5rem;
    .xxbos {
      padding: .15rem 0;
    }
    .bnt {margin-left: .4rem}
  }
  .yz {
    width: 11.4rem;
    padding: 0;
    background: #ffffff;
    margin-left: .15rem;
  }
  .h2 {height: 2rem;}
}
::v-deep .dxs .el-checkbox .el-checkbox__label {display: none!important;}
.hx {
  width: 100%;
  height: 5px;
  background: #bbbaba;
  margin: .15rem 0;
}
.icdBox {
  display: flex;
  justify-content: start;
  padding-top: 10px;
  .icd {
    background: #f6f6f6;
    height: 155px;
    padding: 0.2rem;
    margin-left: .1rem;
    .sou {
      display: flex;
      align-items: center;
      .srk {
        width: 13vw;
        margin-left: .2rem;
        margin-right: .1rem;
      }
      i{font-size: 18px;cursor: pointer;}
    }
    .icd-nr {
      height: 110px;
      margin-top: 1vh;
      overflow-y: auto;
      text-align: left;
      li {
        padding: 5px 0;
        .icdnrs {
          width: 3.7rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
      }
      li:hover{background: #e5e3e3;}
    }
  }
}
.icdlistss {
  cursor: pointer;
  max-height: 400px;
  overflow-y: auto;
  li {
    padding: 8px 0;
    &:hover{background: #e3e3e3;}
  }
}
.addnr {
  margin-top: 10px;
  margin-bottom: 20px;
}
.mg {
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yydc-xx {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-top: 10px;
  span {color: #ff0101;}
  .yydc-yb {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    p {
      margin-right: 10px;
      border: 1px solid #7f7e7e;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: #0000e5;
    }
    div {padding-right: 10px;}
    .ybxz {width: 260px;}
  }
  .yylx {width: 333px}
  .bt {
    font-weight: bold;
  }
}
</style>
